import * as CONST from "./constant";

export const nearbyRestaurant = (payload, callBack) => {
  return {
    type: CONST.NEARBY_RESTAURANT,
    payload: { ...payload },
    callBack,
  };
};

export const nearbyRestaurantSuccess = (payload) => ({
  type: CONST.NEARBY_RESTAURANT_SUCCESS,
  payload,
});

export const nearbyRestaurantFail = (payload) => ({
  type: CONST.NEARBY_RESTAURANT_FAIL,
  payload,
});

//=================Collection Tab==============////

export const collectionTab = (payload, callBack) => {
  return {
    type: CONST.COLLECTION_TAB,
    payload: { ...payload },
    callBack,
  };
};

export const collectionTabSuccess = (payload) => ({
  type: CONST.COLLECTION_TAB_SUCCESS,
  payload,
});

export const collectionTabFail = (payload) => ({
  type: CONST.COLLECTION_TAB_FAIL,
  payload,
});

// ========== restaurant detail ============ //

export const restaurantDetail = (payload) => {
  return {
    type: CONST.RESTAURANT_DETAIL,
    payload: payload,
  };
};

export const restaurantDetailSuccess = (payload) => ({
  type: CONST.RESTAURANT_DETAIL_SUCCESS,
  payload,
});

export const restaurantDetailFail = (payload) => ({
  type: CONST.RESTAURANT_DETAIL_FAIL,
  payload,
});

// =========freshMarket Details =================//
export const freshMarkettDetail = (payload) => {
  return {
    type: CONST.FRESH_MARKET_DETAIL,
    payload: payload,
  };
};

export const freshMarkettDetailSuccess = (payload) => ({
  type: CONST.FRESH_MARKET_DETAIL_SUCCESS,
  payload,
});

export const freshMarkettDetailFail = (payload) => ({
  type: CONST.FRESH_MARKET_DETAIL_FAIL,
  payload,
});

// =============== LAT LNG ================== //

export const latLng = (payload) => {
  return {
    type: CONST.LAT_LNG,
    payload: payload,
  };
};
