import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <section className="footer-main">
      <div className="mx-3 mx-md-4">
        <Row className="justify-content-around">
          <Col lg={3} md={6} sm={12}>
            <div className="footer-div">
              <h5>Company</h5>
              <ul>
                <li>
                  <a
                    href="https://imsohungry.com/Customer/About"
                    target="blank"
                  >
                    About
                  </a>
                </li>
                <li>
                  {/* <a href="https://imsohungry.com/Customer/FAQ" target="blank">
                    FAQ
                  </a> */}
                  <a href="/faq">FAQ</a>
                </li>
                {/* <li>
                  <a
                    href="https://imsohungry.com/Customer/TermCondition"
                    target="blank"
                  >
                    Terms
                  </a>
                </li> */}
                <li>
                  <a
                    href="/privacy"
                    // href="https://imsohungry.com/Customer/PrivacyPolicy"
                    // target="blank"
                  >
                    Security & Privacy
                  </a>
                </li>
              </ul>
              {/* <ul className="social-icon">
                <li>
                  <a href="javascript:void(0)" className="facebook">
                    <svg
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--uil"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M15.12 5.32H17V2.14A26.11 26.11 0 0 0 14.26 2c-2.72 0-4.58 1.66-4.58 4.7v2.62H6.61v3.56h3.07V22h3.68v-9.12h3.06l.46-3.56h-3.52V7.05c0-1.05.28-1.73 1.76-1.73Z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="twitter">
                    <svg
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--mdi"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="youtube">
                    <svg
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ps"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 384 488"
                    >
                      <path
                        fill="currentColor"
                        d="M325 339h-25v-15q0-12 12-12h1q12 0 12 12v15zm-95-32q-12 0-12 10v71q0 10 12 10t12-10v-71q0-10-12-10zm154-40v136q0 25-18.5 42T321 462H63q-26 0-44.5-17T0 403V267q0-24 18.5-41.5T63 208h258q26 0 44.5 17.5T384 267zM80 418V275h32v-21l-85-1v21h26v144h27zm96-122h-27v95q-2 5-7 6.5t-8-5.5v-19l-1-77h-26v100q2 17 7 20q9 6 22.5.5T155 402v16h21V296zm85 88v-64q0-19-12-26t-30 7v-47h-27v163h22l2-11q21 18 33.5 10t11.5-32zm84-9h-20v14q0 11-11 11h-4q-11 0-11-11v-29h46v-17q0-26-1-33q-3-16-21.5-20t-30.5 5q-8 7-11 15q-3 10-3 27v38q0 37 28 43q24 5 35-16q7-11 4-27zM242 169q2 5 7 8q4 3 11 3q6 0 10-3t7-9v10h30V51h-24v99q0 10-10 10q-9 0-9-10V51h-25v86q0 18 1 22q0 4 2 10zm-90-71q0-18 3-29q3-9 11-16q8-6 20-6q11 0 18 4q7 3 11 10q4 5 6 13q1 5 1 21v32q0 20-1 26q-1 7-6 15q-3 5-11 11q-8 3-16 3q-10 0-18-3q-7-3-11-9q-4-8-5-14q-2-10-2-25V98zm23 50q0 5 3.5 9t8.5 4q12 0 12-13V81q0-13-12-13t-12 13v67zm-82 34h28V85l33-83h-30l-18 62L88 2H58l35 83v97z"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="instagram">
                    <svg
                      aria-hidden="true"
                      role="img"
                      class="iconify iconify--ant-design"
                      width="18"
                      height="18"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 1024 1024"
                    >
                      <path
                        fill="currentColor"
                        d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1S717.1 625.5 717.1 512S625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7S645.3 438.6 645.3 512S585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9s47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8c-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1c-18.2-7.3-31.8-16.1-45.8-30.2c-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9c7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2c14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z"
                      />
                    </svg>
                  </a>
                </li>
              </ul> */}
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className="footer-div">
              <h5>Become a Partner</h5>
              <ul>
                <li>
                  <a
                    href="https://restaurent.imsohungry.com/#/signin"
                    target="blank"
                  >
                    Restaurant SignUp
                  </a>
                </li>
                <li>
                  <a href="/driversignup">Driver SignUp</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className="footer-div">
              <h5>Help & Contact</h5>
              <ul>
                <li>
                  <a href="javascript:void(0)">520-891-1700</a>
                </li>
                <li>
                  <a href="javascript:void(0)">support@imsohungry.com</a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    2813 E Broadway Blvd Tucson, AZ 85716
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <div className="footer-copyright text-center">
          <p>© {new Date().getFullYear()} IMSH | All rights reserved.</p>
        </div>
      </div>
    </section>
  );
}
